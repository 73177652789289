import Head from "next/head"
import { useEffect } from "react"
import { NextPage } from "next"
import { useRouter } from "next/router"
import { useCookies } from "react-cookie"
import { DEFAULT_META_IMAGE_URL } from "@utils/constant"

const HomePage: NextPage = () => {
  const router = useRouter()
  const [cookies] = useCookies(["idToken"])

  useEffect(() => {
    if (cookies.idToken) {
      router.push("/dashboard")
    } else {
      router.push("/activities")
    }
  }, [cookies.idToken, router])

  const title = "MySkillShaala"
  const description = "Hobby Community, DIY Activity Kits, Fun Challenges and More"
  const image = DEFAULT_META_IMAGE_URL

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}`} />
        <meta property="og:site_name" content={process.env.NEXT_PUBLIC_SITE_URL} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}`} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
      </Head>
    </>
  )
}

export default HomePage
